import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as S3 from 'aws-sdk/clients/s3';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  fileRequest;
  constructor() { }

  uploadFile(file: File, apiUrl: string): Observable<any> {
    return new Observable((observer) => {
      const formData = new FormData();
      formData.append('file', file); // Attach the file with the key expected by the backend
  
      const xhr = new XMLHttpRequest();
      xhr.open('POST', apiUrl, true);
  
      // Monitor progress
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          observer.next({ type: 0, value: event }); // Progress event
        }
      };
  
      xhr.onload = () => {
        if (xhr.status === 200) {
          observer.next({ type: 1, value: JSON.parse(xhr.response) }); // Successful response
          observer.complete();
        } else {
          observer.error({ type: 2, value: xhr.response }); // Error response
        }
      };
  
      xhr.onerror = () => {
        observer.error({ type: 2, value: 'File upload failed' });
      };
  
      xhr.send(formData);
    });
  }

  cancelUpload(): void {
    setTimeout(this.fileRequest.abort.bind(this.fileRequest), 1000);
  }
}
